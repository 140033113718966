import { firebase } from '@firebase/app'
import '@firebase/auth'
import '@firebase/firestore'
import '@firebase/analytics'
import '@firebase/performance'

// firebase init - add your own config here
const firebaseConfig = {
  apiKey: 'AIzaSyAKi4rAtf11cFB1slS33y07TQpwf4wePvo',
  authDomain: 'monkee-note.firebaseapp.com',
  projectId: 'monkee-note',
  storageBucket: 'monkee-note.appspot.com',
  messagingSenderId: '524755907821',
  appId: '1:524755907821:web:4f90b8fa6572ca735a08e4',
  measurementId: 'G-S9HN73D9DF'
}
firebase.initializeApp(firebaseConfig)

// utils
const db = firebase.firestore()
const auth = firebase.auth()
const analytics = firebase.analytics()
const perf = firebase.performance()

const usersCollection = db.collection('users')
const pagesCollection = db.collection('pages')

auth.onAuthStateChanged(user => (user && !user.emailVerified) ? user.sendEmailVerification() : null)

// export utils/refs
export {
  firebase,
  db,
  auth,
  analytics,
  perf,
  usersCollection,
  pagesCollection
}
