<template>
  <div id="app">
    <router-view/>
    <div class="text-center h-100 position-fixed fixed-top d-flex flex-column justify-content-center align-items-center bg-white" v-if="loading">
      <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
      </div>
      <div class="mt-3">
        Monkee casting some magic..
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['loading'])
  }
}
</script>

<style lang="scss">
header .main-image {
  max-width: 190px;
}
</style>
