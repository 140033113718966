<template>
  <div class="home">
    <Header />
    <div v-if="$route.name === 'Home'" class="min-vh-100 px-4 text-center d-flex justify-content-center flex-column">
      <h1 class="display-5 fw-bold">Simple Notes</h1>
      <p>Forever Free. No steroids.</p>
      <div class="col-lg-6 mx-auto">
        <p class="lead mb-4">Arrange all of your notes in one place.<br>Keep all your ideas and be 'clutter-free' with no uneccessary features.</p>
        <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
          <router-link to="/login" class="btn btn-primary btn-lg px-4 me-sm-3 text-white">Sign Me Up</router-link>
        </div>
      </div>
    </div>
    <router-view v-else></router-view>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'

export default {
  name: 'Home',
  components: {
    Header
  }
}
</script>
