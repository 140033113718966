export const FETCH_USER_PROFILE = 'fetchUserProfile'
export const SET_USER_PROFILE = 'setUserProfile'
export const UPDATE_USER_PROFILE = 'updateUserProfile'
export const UPDATE_USER_EMAIL = 'updateUserEmail'
export const UPDATE_USER_PASSWORD = 'updateUserPassword'
export const UPDATE_DEFAULTS = 'updateDefaults'
export const SET_PERFORMING_REQUEST = 'setPerformingRequest'
export const LOGIN = 'login'
export const SIGNUP = 'signup'
export const LOGOUT = 'logout'
export const SET_LOADING = 'setLoading'

// Pages Actions
export const ADD_NEW_PAGE = 'addNewPage'
export const NEW_PAGE = 'newPage'
export const SET_ACTIVE_PAGE = 'setActivePage'
export const FETCH_USER_PAGES = 'fetchUserPages'
export const FETCH_SHARED_PAGES = 'fetchSharedPages'
export const SET_USER_PAGES = 'setUserPages'
export const UPDATE_ACTIVE_PAGE = 'updateActivePage'
export const RESET_PAGES = 'resetPages'
export const SET_ACTIVE_PAGE_INFO = 'setActivePageInfo'
export const SET_PAGE_DETAILS = 'setPageDetails'
export const DELETE_PAGE = 'deletePage'
export const SET_PAGE_UPDATING = 'setPageUpdating'
export const UPDATE_PAGE_BLOCKS = 'updatePageBlocks'
export const SET_ACTIVE_PAGE_DETAILS = 'setActivePageDetails'
export const SET_SHARED_PAGES = 'setSharedPages'

// Blocks Actions
export const ADD_NEW_BLOCK = 'addNewBlock'
