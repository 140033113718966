import * as fb from '../firebase'
import router from '../router'

import {
  FETCH_USER_PROFILE,
  SET_USER_PROFILE,
  UPDATE_USER_PROFILE,
  SET_PERFORMING_REQUEST,
  LOGIN,
  SIGNUP,
  LOGOUT,
  SET_LOADING,
  UPDATE_DEFAULTS,
  UPDATE_USER_EMAIL,
  UPDATE_USER_PASSWORD,
  RESET_PAGES
} from './actions.type'

const initialState = {
  userProfile: {}
}

export const state = { ...initialState }

export const actions = {
  async [LOGIN] ({ dispatch }, form) {
    // sign user in
    const { user } = await fb.auth.signInWithEmailAndPassword(form.email, form.password)

    // fetch user profile and set in state
    dispatch(FETCH_USER_PROFILE, user)
  },
  async [SIGNUP] ({ dispatch }, form) {
    // sign user up
    const { user } = await fb.auth.createUserWithEmailAndPassword(form.email, form.password)

    await user.updateProfile({
      displayName: form.firstname + ' ' + form.lastname,
      photoURL: 'https://firebasestorage.googleapis.com/v0/b/monkee-note.appspot.com/o/default%2Favatar.png?alt=media'
    })

    // create user object in userCollections
    await fb.usersCollection.doc(user.uid).set({
      firstname: form.firstname,
      lastname: form.lastname,
      email: form.email
    })

    // fetch user profile and set in state
    dispatch(FETCH_USER_PROFILE, user)
  },
  async [FETCH_USER_PROFILE] ({ commit, dispatch, state }, user) {
    // fetch user profile
    const userProfile = await fb.usersCollection.doc(user.uid).get()

    if (!fb.auth.currentUser.displayName &&
    !fb.auth.currentUser.photoURL) {
      dispatch(UPDATE_DEFAULTS, { ...userProfile.data() })
    }

    if (window._hsq) {
      window._hsq.push(['identify', {
        email: fb.auth.currentUser.email
      }])
    }

    // set user profile in state
    commit(SET_USER_PROFILE, {
      ...userProfile.data(),
      id: user.uid,
      auth: {
        photoURL: fb.auth.currentUser.photoURL,
        displayName: fb.auth.currentUser.displayName
      }
    })

    // change route to dashboard
    if (router.currentRoute.path === '/login' ||
      router.currentRoute.path === '/signup' ||
      router.currentRoute.path === '/') {
      if (state.userProfile.lastDocument) {
        router.push('/d/' + state.userProfile.lastDocument)
      } else {
        router.push('/d')
      }
    }
    dispatch(SET_LOADING, false)
  },
  async [UPDATE_DEFAULTS] ({ commit }, data) {
    await fb.auth.currentUser.updateProfile({
      displayName: data.firstname + ' ' + data.lastname,
      photoURL: 'https://firebasestorage.googleapis.com/v0/b/monkee-note.appspot.com/o/default%2Favatar.png?alt=media'
    })
  },
  async [LOGOUT] ({ dispatch, commit }) {
    // log user out
    await fb.auth.signOut()

    // clear user data from state
    commit(SET_USER_PROFILE, {})
    dispatch(RESET_PAGES)

    // redirect to login view
    router.push('/login')
  },
  async [UPDATE_USER_EMAIL] ({ dispatch }, email) {
    const user = fb.auth.currentUser

    await user.updateEmail(email)
    await fb.usersCollection.doc(user.uid).update({
      email
    })
    dispatch(FETCH_USER_PROFILE, { uid: user.uid })
  },
  async [UPDATE_USER_PASSWORD] ({ dispatch }, password) {
    const user = fb.auth.currentUser

    await user.updatePassword(password)

    dispatch(LOGOUT)
  },
  async [UPDATE_USER_PROFILE] ({ dispatch }, data) {
    const userId = fb.auth.currentUser.uid
    // update user object
    // const userRef =

    await fb.auth.currentUser.updateProfile({
      displayName: data.firstname + ' ' + data.lastname
    })
    await fb.usersCollection.doc(userId).update({
      firstname: data.firstname,
      lastname: data.lastname
    })

    dispatch(FETCH_USER_PROFILE, { uid: userId })
  }
}

export const mutations = {
  [SET_USER_PROFILE] (state, val) {
    state.userProfile = val
  },
  [SET_PERFORMING_REQUEST] (state, val) {
    state.performingRequest = val
  }
}

export const getters = {
  user: (state) => {
    return state.userProfile
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
