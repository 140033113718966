import Vue from 'vue'
import Vuex from 'vuex'

import user from './user.module'
import utils from './utils.module'
import pages from './pages.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    pages,
    utils
  }
})
