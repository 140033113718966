import Vue from 'vue'
import * as fb from '../firebase'
import router from '../router'
import { v4 as uuidv4 } from 'uuid'
import { BlockType } from '@/utils/block.type'

import {
  ADD_NEW_PAGE,
  NEW_PAGE,
  FETCH_USER_PAGES,
  SET_ACTIVE_PAGE,
  SET_USER_PAGES,
  UPDATE_ACTIVE_PAGE,
  RESET_PAGES,
  SET_ACTIVE_PAGE_INFO,
  SET_PAGE_DETAILS,
  DELETE_PAGE,
  SET_PAGE_UPDATING,
  UPDATE_PAGE_BLOCKS,
  SET_ACTIVE_PAGE_DETAILS,
  ADD_NEW_BLOCK,
  FETCH_SHARED_PAGES,
  SET_SHARED_PAGES
} from './actions.type'

const initialState = {
  pages: [],
  currentPage: null,
  currentPageInfo: null,
  pageUpdating: false
}

export const state = { ...initialState }

export const actions = {
  async [NEW_PAGE] ({ dispatch, commit }) {
    const currentUser = fb.auth.currentUser
    const defaultBlock = {
      id: uuidv4(),
      type: BlockType.Text,
      data: {
        text: ''
      }
    }
    const newPage = await fb.pagesCollection.add({
      owner: currentUser.uid,
      title: 'Untitled Page',
      blocks: [defaultBlock],
      createdAt: fb.firebase.firestore.Timestamp.now(),
      updatedAt: fb.firebase.firestore.Timestamp.now()
    })

    commit(ADD_NEW_PAGE, {
      id: newPage.id,
      owner: currentUser.uid,
      title: 'Untitled Page',
      blocks: [defaultBlock],
      createdAt: fb.firebase.firestore.Timestamp.now(),
      updatedAt: fb.firebase.firestore.Timestamp.now()
    })
    dispatch(SET_ACTIVE_PAGE, newPage.id)

    router.push('/d/' + newPage.id)
  },
  async [FETCH_USER_PAGES] ({ commit }) {
    const currentUser = fb.auth.currentUser
    const userPages = await fb.pagesCollection.where('owner', '==', currentUser.uid).orderBy('createdAt', 'asc').get()

    commit(SET_USER_PAGES, userPages)
  },
  async [FETCH_SHARED_PAGES] ({ commit }) {
    const currentUser = fb.auth.currentUser
    const sharedPages = await fb.pagesCollection.where('sharedWith', 'array-contains', currentUser.uid).orderBy('createdAt', 'asc').get()

    commit(SET_SHARED_PAGES, sharedPages)
  },
  async [SET_ACTIVE_PAGE] ({ commit, state }, id) {
    const userProfile = fb.usersCollection.doc(fb.auth.currentUser.uid)
    await userProfile.update({
      lastDocument: id
    })

    const pageInfo = state.pages.find(x => x.id === id)

    commit(SET_ACTIVE_PAGE, id)
    commit(SET_ACTIVE_PAGE_INFO, pageInfo)
  },
  async [UPDATE_ACTIVE_PAGE] ({ dispatch, commit, state }, page) {
    dispatch(SET_PAGE_UPDATING, true)
    const pageRef = fb.pagesCollection.doc(state.currentPage)
    commit(SET_ACTIVE_PAGE_DETAILS, {
      ...page,
      updatedAt: fb.firebase.firestore.Timestamp.now()
    })
    await pageRef.update({
      ...page,
      updatedAt: fb.firebase.firestore.Timestamp.now()
    })
    commit(SET_PAGE_DETAILS, {
      ...page,
      updatedAt: fb.firebase.firestore.Timestamp.now()
    })
    dispatch(SET_PAGE_UPDATING, false)
  },
  [UPDATE_PAGE_BLOCKS] ({ dispatch, commit }, page) {
    commit(SET_PAGE_DETAILS, {
      ...page
    })
    dispatch(UPDATE_ACTIVE_PAGE, page)
  },
  [ADD_NEW_BLOCK] ({ dispatch, commit }, page) {

  },
  [RESET_PAGES] ({ commit }) {
    commit(RESET_PAGES, [])
    commit(SET_ACTIVE_PAGE, null)
  },
  [SET_PAGE_UPDATING] ({ commit }, val) {
    commit(SET_PAGE_UPDATING, val)
  },
  async [DELETE_PAGE] ({ commit, state }) {
    const pageRef = fb.pagesCollection.doc(state.currentPage)
    await pageRef.delete()

    const newPage = state.pages.find(x => x.id !== state.currentPage)

    commit(DELETE_PAGE, state.currentPage)
    if (state.pages.length > 0) {
      router.push('/d/' + newPage.id)
      commit(SET_ACTIVE_PAGE, newPage.id)
      commit(SET_ACTIVE_PAGE_INFO, newPage)
    } else {
      const userProfile = fb.usersCollection.doc(fb.auth.currentUser.uid)
      await userProfile.update({
        lastDocument: null
      })
      router.push('/d')
    }
  }
}

export const mutations = {
  [ADD_NEW_PAGE] (state, val) {
    state.pages.push(val)
  },
  [SET_ACTIVE_PAGE] (state, val) {
    state.currentPage = val
  },
  [SET_PAGE_DETAILS] (state, page) {
    Vue.set(state.pages, state.pages.findIndex(x => x.id === page.id), page)
  },
  [SET_ACTIVE_PAGE_DETAILS] (state, page) {
    state.currentPageInfo = page
  },
  [ADD_NEW_BLOCK] (state) {
    state.currentPageInfo.blocks.push({
      id: uuidv4(),
      type: BlockType.Text,
      data: {
        text: ''
      }
    })
  },
  [SET_USER_PAGES] (state, val) {
    if (val.size && val.size > 0) {
      val.forEach(element => {
        state.pages.push({
          id: element.id,
          ...element.data()
        })
      })
    }
  },
  [RESET_PAGES] (state) {
    state.pages = []
  },
  [SET_ACTIVE_PAGE_INFO] (state, val) {
    state.currentPageInfo = val
  },
  [DELETE_PAGE] (state, id) {
    const index = state.pages.findIndex(function (i) {
      return i.id === id
    })
    state.pages.splice(index, 1)
  },
  [SET_PAGE_UPDATING] (state, val) {
    state.pageUpdating = val
  }
}

export const getters = {
  pages: (state) => {
    return state.pages
  },
  currentPage: (state) => {
    return state.currentPage
  },
  currentPageInfo: (state) => {
    return state.currentPageInfo
  },
  pageUpdating: (state) => {
    return state.pageUpdating
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
