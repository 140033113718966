import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import { auth } from '../firebase'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '/signup',
        name: 'Sign Up',
        component: () => import(/* webpackChunkName: "login" */ '../views/Signup.vue'),
        meta: {
          title: 'Monkee – Signup',
          metaTags: [
            {
              name: 'description',
              content: 'Create your Monkee account.'
            },
            {
              property: 'og:description',
              content: 'Create your Monkee account.'
            }
          ]
        }
      },
      {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
        meta: {
          title: 'Monkee – Login',
          metaTags: [
            {
              name: 'description',
              content: 'Log in your Monkee account.'
            },
            {
              property: 'og:description',
              content: 'Log in your Monkee account.'
            }
          ]
        }
      },
      {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
        meta: {
          title: 'About Monkee',
          metaTags: [
            {
              name: 'description',
              content: 'Everything you ever wanted to know about Monkee.'
            },
            {
              property: 'og:description',
              content: 'Everything you ever wanted to know about Monkee.'
            }
          ]
        }
      }
    ],
    meta: {
      title: 'Monkee – Notes without steroids',
      metaTags: [
        {
          name: 'description',
          content: 'Arrange all of your notes in one place. Keep all your ideas and be \'clutter-free\' with no uneccessary features.'
        },
        {
          property: 'og:description',
          content: 'Arrange all of your notes in one place. Keep all your ideas and be \'clutter-free\' with no uneccessary features.'
        }
      ]
    }
  },
  {
    path: '/d',
    name: 'Pages',
    component: () => import('../views/Pages.vue'),
    children: [
      {
        path: ':id',
        name: 'Page',
        component: () => import('../views/Page.vue')
      }
    ],
    beforeEnter: (to, from, next) => {
      if (auth.currentUser) {
        next()
      } else {
        next('/login')
      }
    }
  }
]

const defaultTitle = 'Monkee – Notes without steroids'
const defaultMeta = 'Arrange all of your notes in one place. Keep all your ideas and be \'clutter-free\' with no uneccessary features.'
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const appendMeta = obj => {
  obj.map(tagDef => {
    const tag = document.createElement('meta')

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key])
    })
    tag.setAttribute('data-vue-router-controlled', '')

    return tag
  })
    .forEach(tag => document.head.appendChild(tag))
}

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)

  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title
  }

  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

  if (!nearestWithMeta) {
    document.title = defaultTitle
    const tags = [{
      name: 'description',
      content: defaultMeta
    }]
    appendMeta(tags)
    return next()
  }
  appendMeta(nearestWithMeta.meta.metaTags)

  next()
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

  if (requiresAuth && !auth.currentUser) {
    next('/login')
  } else {
    next()
  }
})

export default router
