import {
  SET_LOADING
} from './actions.type'

const initialState = {
  loading: false
}

export const state = { ...initialState }

export const actions = {
  [SET_LOADING] ({ commit }, loading) {
    commit(SET_LOADING, loading)
  }
}

export const mutations = {
  [SET_LOADING] (state, val) {
    state.loading = val
  }
}

export const getters = {
  loading: (state) => {
    return state.loading
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
