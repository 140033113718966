import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { auth } from './firebase'
import './assets/styles/custom.scss'
import 'bootstrap'

import {
  FETCH_USER_PROFILE,
  SET_LOADING
} from './store/actions.type'

const filter = function (text, length, clamp) {
  clamp = clamp || '...'
  return text.length > length ? text.slice(0, length) + clamp : text
}

Vue.filter('truncate', filter)

store.dispatch(SET_LOADING, true)
auth.onAuthStateChanged(async (user) => {
  if (user) {
    store.dispatch(FETCH_USER_PROFILE, user)
  } else {
    store.dispatch(SET_LOADING, false)
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
